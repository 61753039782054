// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-graphics-js": () => import("./../../../src/pages/graphics.js" /* webpackChunkName: "component---src-pages-graphics-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sections-about-js": () => import("./../../../src/pages/sections/about.js" /* webpackChunkName: "component---src-pages-sections-about-js" */),
  "component---src-pages-sections-contact-js": () => import("./../../../src/pages/sections/contact.js" /* webpackChunkName: "component---src-pages-sections-contact-js" */),
  "component---src-pages-sections-design-js": () => import("./../../../src/pages/sections/design.js" /* webpackChunkName: "component---src-pages-sections-design-js" */),
  "component---src-pages-work-asksimple-js": () => import("./../../../src/pages/work/asksimple.js" /* webpackChunkName: "component---src-pages-work-asksimple-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-pages-work-nimbusrun-js": () => import("./../../../src/pages/work/nimbusrun.js" /* webpackChunkName: "component---src-pages-work-nimbusrun-js" */),
  "component---src-pages-work-selfi-corporate-js": () => import("./../../../src/pages/work/selfi-corporate.js" /* webpackChunkName: "component---src-pages-work-selfi-corporate-js" */),
  "component---src-pages-work-selfi-personal-js": () => import("./../../../src/pages/work/selfi-personal.js" /* webpackChunkName: "component---src-pages-work-selfi-personal-js" */),
  "component---src-pages-work-sutdgradnight-js": () => import("./../../../src/pages/work/sutdgradnight.js" /* webpackChunkName: "component---src-pages-work-sutdgradnight-js" */),
  "component---src-pages-work-sutdring-js": () => import("./../../../src/pages/work/sutdring.js" /* webpackChunkName: "component---src-pages-work-sutdring-js" */),
  "component---src-pages-work-threadparadise-js": () => import("./../../../src/pages/work/threadparadise.js" /* webpackChunkName: "component---src-pages-work-threadparadise-js" */),
  "component---src-pages-work-timeui-js": () => import("./../../../src/pages/work/timeui.js" /* webpackChunkName: "component---src-pages-work-timeui-js" */),
  "component---src-templates-author-js": () => import("./../../../src/templates/author.js" /* webpackChunkName: "component---src-templates-author-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-tag-js": () => import("./../../../src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */)
}

